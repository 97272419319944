import SharedVoucher from '@/shared/models/Voucher'

export default class Voucher extends SharedVoucher {
    isWithdrawable() {
        return !this.isWithdrawn() && !this.isExpired()
    }

    isWithdrawn() {
        return this.withdrawn_at
    }

    isExpired() {
        return this.expires_at && Date.now() > this.expires_at
    }
}