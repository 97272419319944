import BaseModel from '@/models/BaseModel'
import Subsidiary from '@/models/Subsidiary'
import Voucher from '@/models/Voucher'
import Point from '@/models/Point'
import { roundNumber } from '@/utils/mathUtils'

export default class SimpleFlowModel extends BaseModel {

    defaults() {
        return {
            id           : null,
            source_key   : null,
            value        : null,
            integrator_id: null,
            type_id      : null,
            external_id  : null,
            created_at   : null,
            updated_at   : null,
            reversal_id  : null,
        }
    }

    relationships() {
        return {
            company: { class: Subsidiary },
            vouchers: { class: [Voucher] },
            points:  { class: [Point] },
        }
    }

    // Não precisa de validação, pois não havera o cadastro nesta app

    // Utils

    isReversed() {
        return !!this.reversal_id
    }

    // Getters

    get subsidiary_name() {
        return this.company ? this.company.name : ''
    }

    get subsidiary_document() {
        return this.company ? this.company.document : ''
    }

    get formated_subsidiary_document() {
        return this.company && this.company.document ? this.company.document.replace(/\D/g, '').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5') : ''
    }

    get points_amount() {
        return Array.isArray(this.points) ? this.points.reduce((carry, current) => Number(roundNumber(carry + current.amount)), 0) : 0
    }

    get points_value() {
        return Array.isArray(this.points) ? this.points.reduce((carry, current) => Number(roundNumber(carry + current.value)), 0) : 0
    }

    get points_percentage() {
        return this.points ? Number(roundNumber((this.points_value / this.value) * 100)) : 0
    }
}