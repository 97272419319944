import SimpleFlowModel from '@/models/SimpleFlowModel'
import CurrencyFilter from '@/utils/filters/currency'
import i18n from '@/i18n'
import $store from '@/store'

export default class Consumption extends SimpleFlowModel {

    defaults() {
        return {
            ...super.defaults(),
            intention_id: null,
        }
    }

    hasVoucher() {
        return this.source_key === "FID-VOUCHER" || this.vouchers.length
    }

    // Não precisa de validação, pois não havera o cadastro nesta app

    // Getters

    /**
     * Retorna a string identificadora de um ícone que representa o consumo
     *
     * @returns {string}
     */
    get icon() {
        return '$cp_gift'
    }

    get title() {
        let displayMode = $store.getters['company/display_mode']
        let hidePoints = $store.getters['company/hide_points']
        let hideCashback = $store.getters['company/hide_cashback']

        if (this.hasVoucher()) {
            if (!this.value) {
                return i18n.t('models.Consumption.title_voucher_free', {
                    subsidiary_name: this.subsidiary_name,
                })
            }

            if (hidePoints) {
                return i18n.t('models.Consumption.title_voucher_hide_points', {
                    subsidiary_name: this.subsidiary_name,
                })
            }

            if (hideCashback) {
                return i18n.t('models.Consumption.title_voucher_hide_cashback', {
                    points_amount  : this.points_amount,
                    subsidiary_name: this.subsidiary_name,
                })
            }

            return i18n.t('models.Consumption.title_voucher', {
                points_amount  : this.points_amount,
                points_value   : CurrencyFilter(this.points_value),
                subsidiary_name: this.subsidiary_name,
            })
        }

        if (hidePoints) {
            return i18n.t('models.Consumption.title_hide_points', {
                points_amount  : this.points_amount,
                subsidiary_name: this.subsidiary_name,
            })
        }

        if (hideCashback) {
            return i18n.t('models.Consumption.title_hide_cashback', {
                points_amount  : this.points_amount,
                subsidiary_name: this.subsidiary_name,
            })
        }

        if (displayMode == 'PERCENTAGE' || displayMode == 'CASH') {
            return i18n.t('models.Consumption.title_percentage_cash', {
                points_value   : CurrencyFilter(this.points_value),
                subsidiary_name: this.subsidiary_name,
            })
        }

        return i18n.t('models.Consumption.title_points', {
            points_amount  : this.points_amount,
            points_value   : CurrencyFilter(this.points_value),
            subsidiary_name: this.subsidiary_name,
        })
    }

    get titleMobile() {
        if (this.hasVoucher()) {
            if (!this.value) {
                return i18n.t('models.Consumption.title_voucher_free', {
                    subsidiary_name: this.subsidiary_name,
                })
            }
        }

        let hidePoints = $store.getters['company/hide_points']
        if (hidePoints) {
            return i18n.t('models.Consumption.title_mobile_hide_points')
        }

        return i18n.t('models.Consumption.title_mobile', {
            points_amount  : this.points_amount,
            subsidiary_name: this.subsidiary_name,
        })
    }

    get action() {
        return i18n.t('models.Consumption.action')
    }

    /**
     * Retorna um subtítulo indicando se houve cancelamento
     *
     * @returns {string}
     */
    get subtitle() {
        if (!this.reversal_id) {
            return ''
        }

        let hidePoints = $store.getters['company/hide_points']
        if (hidePoints) {
            return i18n.t('models.Consumption.subtitle.reversal_hide_points')
        }

        return i18n.t('models.Consumption.subtitle.reversal')
    }

    /**
     * @override
     *
     * Retorna o valor total dos pontos consumidos
     * 
     * @returns {Number}
     */
    get points_value() {
        return this.value
    }
}